
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MotorInsuranceFormEdit from "@/components/reusable/MotorInsuranceFormEdit.vue";
import { Form, Field } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";

export default defineComponent({
  name: "motor-insurance-package-edit-quote",
  components: {
    Form,
    Field,
    MotorInsuranceFormEdit,
    TotalPremiumWidget
  },

  data() {
    return {
      policySummary: {},
      publicId: this.$route.params.publicId,
      totalPremium: 0.0 as number,
      totalSumInsured: 0.0 as number,
      displayBreakdown: false,
      covers: [],
      packageType: "",
      packageShort: "",
      metadatas: []
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const metadataValidator = Yup.object().shape({
      metadata: Yup.array()
        .of(
          Yup.object().shape({
            vehicleOwner: Yup.string().required().label("Vehicle owner name"),
            vehicleValue: Yup.mixed().required().label("Vehicle value"),
            package: Yup.mixed().required().label("Cover"),
            vehicleMake: Yup.mixed().required().label("Vehicle brand"),
            vehicleModel: Yup.string().required().label("Vehicle model"),
            vehicleRegistrationNumber: Yup.string()
              .required()
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string().required().min(1).label("Engine number"),
            bodyType: Yup.string().required().label("Body type"),
            vehicleColor: Yup.string().required().label("Vehicle colour"),
            vehicleLicense: Yup.mixed().label("Vehicle license")
          })
        )
        .strict()
    });

    const updateCartInvoice = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["metadata"].length; x++) {
              for (const [key, value] of Object.entries(
                values["metadata"][x]
              )) {
                if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            if (
              formData.get("vehicleDetails[vehicleLicense][0]") === "undefined"
            ) {
              formData.set("vehicleDetails[vehicleLicense][0]", "");
              // formData.delete("vehicleDetails[vehicleLicense][0]");
            }

            if (
              formData.get("vehicleDetails[vehicleLicense][1]") === "undefined"
            ) {
              formData.set("vehicleDetails[vehicleLicense][1]", "");
            }

            if (
              formData.get("vehicleDetails[vehicleLicense][2]") === "undefined"
            ) {
              formData.set("vehicleDetails[vehicleLicense][2]", "");
            }

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", values["businessClass"]);
            formData.append("underwriter", values["underwriter"]);
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${variables.CART_ROUTE}/${values["publicId"]}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId
                  }
                });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Invoice", [
        "Insurance Packages",
        "Motor Insurance",
        "Cart"
      ]);
    });

    return {
      submitButton1,
      updateCartInvoice,
      metadataValidator
    };
  },
  created() {
    // Get invoice information
    this.getInvoiceInformation();

    setTimeout(() => {
      this.getMotorInsuranceTypeCovers();
    }, 2500);

    //Set page title
    document.title =
      "Edit Motor Insurance Invoice | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getInvoiceInformation: function () {
      const router = useRouter();

      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policySummary = data.data;
            this.metadatas = data.data.vehicleDetails;
            this.totalPremium = data.data.premium;
            this.totalSumInsured = data.data.sumInsured;
            this.packageType = data.data.businessType;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.push({ name: "insurance-packages-cart" });
          });
      }
    },
    getMotorInsuranceTypeCovers() {
      // variables.resetPremium();
      let route = "";

      if (this.packageType == "Private Motor") {
        route = variables.PRIVATE_MOTOR_RATE_ROUTE;
        this.packageShort = "PM";
      } else {
        route = variables.COMMERCIAL_MOTOR_RATE_ROUTE;
        this.packageShort = "CM";
      }

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(route)
          .then(({ data }) => {
            //Assign data to form fields
            this.covers = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
